$(function () {
  //Mobile menu trigger
  $("#mobileMenuTrigger").click(function () {
    $(".pageHeader-nav").before('<div class="pageHeader-navMobileOverlay" />');
    $(".pageHeader-navMobileOverlay").addClass("is-visible");
    $(".pageHeader-nav").addClass("is-visible");
    $("body").css("overflow", "hidden");
  });
  $("#mobileMenuClose").click(function () {
    $(".pageHeader-nav").removeClass("is-visible");
    $(".pageHeader-navMobileOverlay").remove();
    $("body").css("overflow", "auto");
  });

  //Search overlay 
  $("#searchBtnTrigger").click(function () {
    if (PageCanHaveTransparentPageHeader()) {
      $(".pageHeader").removeClass("is-transparent");
    }
    $(".pageHeader-searchOverlay").addClass("is-visible");
    $(".pageHeader-searchInput").focus();
  });
  $("#searchBtnClose").click(function () {
    if (PageCanHaveTransparentPageHeader()) {
      if (ScrollIsInTransparentPosition()) {
        $(".pageHeader").addClass("is-transparent");
      } else {
        $(".pageHeader").removeClass("is-transparent");
      }
    }
    $(".pageHeader-searchOverlay").removeClass("is-visible");
  });

  //On mobile
  $(".pageHeader-navListLink, .pageHeader-navListTitle").click(function (e) {
    if ($("#mobileMenuClose").is(':visible') || $("#mobileMenuTrigger").is(':visible')) {
      if ($(this).parent(".pageHeader-navListItem").hasClass("has-children")) {
        e.preventDefault;
        $(this).parent(".has-children").toggleClass("is-expanded");
        return false;
      }
    }
  });

  //Deal with the transparent menu
  //If header is supposed to be transparent
  if (PageCanHaveTransparentPageHeader()) {
    $(window).scroll(function () {
      //if scrollbar surpasses the .serviceMenu height
      if (!ScrollIsInTransparentPosition()) {
        $(".pageHeader").removeClass("is-transparent");
      } else {
        if (!HasOpenMenuElements() && !SearchbarIsVisible()) {
          $(".pageHeader").addClass("is-transparent");
        }
      }
    });
  }
  $(".pageHeader").on("mouseenter", function () {
    if (breakpoint.value === 'md' || breakpoint.value === 'lg') {
      var cn = "is-transparent";
      var mlevent = "mouseleave." + cn;
      $(this).filter("." + cn).removeClass(cn).on(mlevent, function () {
        if (!HasOpenMenuElements() && !SearchbarIsVisible()) {
          $(this).addClass(cn).unbind(mlevent);
        }
      });
    }
  });
  $('.pageHeader-dropdown.dropdown').on('hide.bs.dropdown', function () {
    if (PageCanHaveTransparentPageHeader()) {
      if (ScrollIsInTransparentPosition()) {
        $(".pageHeader").addClass("is-transparent");
      }
    }
  });
  $('.pageHeader-dropdown.dropdown').on('show.bs.dropdown', function () {
    if (PageCanHaveTransparentPageHeader()) {
      $(".pageHeader").removeClass("is-transparent");
    }
  });

  //Don't close the dropdown if clicking inside the container
  $('body').on("click", ".pageHeader .dropdown-menu", function (e) {
    $(this).parent().is(".open") && e.stopPropagation();
  });
});
function PageCanHaveTransparentPageHeader() {
  return $(".pageHeader[data-transparent]").length > 0;
}
function HasOpenMenuElements() {
  var openMegaMenus = $('.pageHeader-dropdown.dropdown.open, .marketSelector.dropdown.open');
  return openMegaMenus !== null && openMegaMenus.length > 0;
}
function ScrollIsInTransparentPosition() {
  var serviceMenuHeight = $(".serviceMenu").outerHeight();
  var scrollAmount = $(window).scrollTop();
  return scrollAmount < serviceMenuHeight;
}
function SearchbarIsVisible() {
  return $('.pageHeader-searchInputContainer:visible').length > 0;
}
function updateVisualCartIcon(numberOfItemsInCart) {
  var showAddedToCartMessageOverlay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  $("[data-cartQuantity]").text(numberOfItemsInCart);
  if (showAddedToCartMessageOverlay) {
    showAddedToCartMessage();
  }
  $("[data-cartQuantity]").show();
  if (numberOfItemsInCart == 0) {
    $("[data-cartQuantity]").hide();
  }
}
function showAddedToCartMessage() {
  var $cartLink = $(".pageHeader-cartLink").filter(":visible").first();
  if ($cartLink.length) {
    var elAddedToCart = "<div id='addedToCart' class='pageHeader-cart-addedToCartMessage'>" + "<div class='u-textBold20'>" + $cartLink.data("cartupdatedtext") + "</div>" + "<a href='" + $cartLink.data("addedtocartlink") + "' class='btn btn-primary mt-'>" + $cartLink.data("addedtocartlinktext") + "</a>" + "</div>";
    $(elAddedToCart).appendTo($cartLink);
    setTimeout(function () {
      $('#addedToCart').remove();
    }, 3000);
  }
}